import { format, parseISO } from "date-fns";
import { calculateMinMax, getMeasurementType, getSensorUnit } from "./functions";
import { ModifiedConstants } from "../App";

export const generateSeriesData = (originalSeries, selectedSensor, selectedLocation) => {
  return originalSeries?.map((item) => ({
    ...item,
    sensor: selectedSensor,
    location: selectedLocation
  }));
};

export const getDominantType = (selectedSensor, selectedLocation) => {
  if (selectedSensor.length > selectedLocation.length) return "sensor";
  if (selectedLocation.length > selectedSensor.length) return "location";
  return null; // No dominant type
};

export const getLabelUnit = (dominantType, architectureSources) => {
  if (dominantType === "location" && architectureSources.length > 0) {
    return getSensorUnit(architectureSources[0].name);
  }
  return null;
};
export const formatSeries = (bucket, timeDif, mappingSensor, ...seriesList) => {
  const dateFormat = getFormatFromBucket(bucket, timeDif);
  const architectureSources = [];
  const consolidatedData = [];
  const unitToSensorMap = {};

  seriesList.forEach((series, index) => {
    if (series && series.length > 0) {
      const sensorName = series[0]?.sensor || undefined;
      const locationName = series[0]?.location || undefined;
      let sensorValue = sensorName?.toLowerCase().replace(/\s+/g, "");
      let type = "";
      if (sensorName) {
        type = "sensor";
      } else if (locationName) {
        type = "location";
      } else {
        sensorValue = "Alerts";
        type = "alert";
      }

      architectureSources.push({
        value: sensorValue,
        name: sensorValue === "Alerts" ? "Alerts" : sensorName || locationName,
        type: type
      });

      const unit = getSensorUnit(sensorName);

      if (!unitToSensorMap[unit]) {
        unitToSensorMap[unit] = [];
      }

      unitToSensorMap[unit].push({
        value: sensorValue,
        name: sensorName,
        unit
      });

      series.forEach(({ to, avg, sum, sensor, location, name }) => {
        const date = format(parseISO(to), dateFormat ? dateFormat : "d MMM");
        const measurementType = getMeasurementType(mappingSensor ? mappingSensor : sensorName);
        const measurement = measurementType === ModifiedConstants.MEASUREMENT_TYPE.SUM ? sum : avg;

        let existingEntry = consolidatedData.find((entry) => entry.date === date);

        if (existingEntry) {
          if (name) {
            existingEntry["Alerts"] = measurement;
          } else if (sensor) {
            existingEntry[sensorValue] = measurement;
          } else if (location && sensor === null) {
            existingEntry[locationName] = measurement;
          }
        } else {
          const newEntry = { date };
          if (sensorName) {
            newEntry[sensorValue] = measurement;
          } else if (locationName) {
            newEntry[locationName] = measurement;
          } else if (name) {
            newEntry["Alerts"] = measurement;
          }
          consolidatedData.push(newEntry);
        }
      });
    }
  });

  return { architectureSources, consolidatedData, unitToSensorMap };
};

export const formatMultisenseSeries = (bucket, timeDif, mappingSensor, ...seriesList) => {
  const dateFormat = getFormatFromBucket(bucket, timeDif);
  const architectureSources = [];
  const consolidatedData = [];
  const unitToSensorMap = [];

  seriesList.forEach((series, index) => {
    if (series && series.length > 0) {
      const seriesName = series[index]?.name || undefined;
      let sensorValue = series[index]?.sensor || undefined;
      let type = "sensor";

      architectureSources.push({
        value: sensorValue,
        name: seriesName,
        type: type,
        color: series[index]?.color,
        chartType: series[index]?.type
      });

      const unit = getSensorUnit(series[index].sensor);

      const { min, max } = calculateMinMax(series);
      unitToSensorMap.push({
        value: sensorValue,
        name: seriesName,
        unit,
        min,
        max
      });

      series.forEach(({ to, avg, sum, name }) => {
        const date = format(parseISO(to), dateFormat ? dateFormat : "d MMM");
        const measurementType = getMeasurementType(sensorValue);
        const measurement = measurementType === ModifiedConstants.MEASUREMENT_TYPE.SUM ? sum : avg;

        let existingEntry = consolidatedData.find((entry) => entry.date === date);

        if (existingEntry) {
          existingEntry[name] = measurement;
        } else {
          const newEntry = { date };
          newEntry[name] = measurement;
          consolidatedData.push(newEntry);
        }
      });
    }
  });

  return { architectureSources, consolidatedData, unitToSensorMap };
};

export const getAverage = (data, sensor) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }

  const measurementType = getMeasurementType(sensor);
  if (measurementType === 0) {
    const total = data.reduce((acc, obj) => acc + obj.avg, 0);
    const average = total / data.length;
    return average;
  }

  if (measurementType === 1) {
    const sum = data.reduce((acc, obj) => acc + obj.sum, 0);
    const average = sum / data.length;
    return average;
  }

  return 0; // Default case
};

export const getFormatFromBucket = (bucket, timeDif) => {
  if (timeDif === 0) {
    switch (bucket) {
      case ModifiedConstants.BUCKET_TIME.HOUR:
        return "HH:00";
      case ModifiedConstants.BUCKET_TIME.MINUTE:
        return "HH:mm";
      case ModifiedConstants.BUCKET_TIME.DAY:
        return "d MMM";
      default:
        return "HH:00";
    }
  } else {
    switch (bucket) {
      case ModifiedConstants.BUCKET_TIME.HOUR:
        return "HH:00";
      case ModifiedConstants.BUCKET_TIME.MINUTE:
        return "d MMM HH:mm";
      default:
        return "d MMM";
    }
  }
};

export const getMaxValue = (data, sensor) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // or return a default value
  }
  const measurementType = getMeasurementType(sensor);
  if (measurementType === 0) {
    const maxVal = Math.max(...data?.map((obj) => obj.avg));
    return maxVal;
  }
  if (measurementType === 1) {
    const maxVal = Math.max(...data?.map((obj) => obj.sum));
    return maxVal;
  }
};

export const getSum = (data, sensor) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // or return a default value
  }
  const totalSum = data.reduce((acc, obj) => acc + obj.sum, 0);
  return totalSum;
};

export const getDifference = (current, previous) => {
  if (!current && !previous) return 0;
  if (!current) return -100; // If current is 0 and previous is not, then it's a decrease of 100%
  if (!previous) return 100; // If previous is 0 and current is not, then it's an increase of 100%
  const difference = current - previous;
  return ((difference / previous) * 100).toFixed(2);
};
